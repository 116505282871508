[type='checkbox']:checked,
[type='checkbox']:not(:checked) {
  /* position: absolute; */
  opacity: 0;
  /* pointer-events: none; */
}
.option_group {
  margin-top: 100px;
}
.option_group_shadow {
  box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.1);
}
.switch label .lever {
  content: '';
  display: inline-block;
  position: relative;
  background-color: #818181;
  border-radius: 0.9375rem;
  margin-right: 0.625rem;
  vertical-align: middle;
  margin: 0 1rem;
  width: 2.5rem;
  height: 0.375rem;
}

.switch label input[type='checkbox']:checked + .lever {
  background-color: #dccfe2;
}

.switch label input[type='checkbox']:checked + .lever:after {
  background-color: #be0a27;
  left: 1.5rem;
}
.switch label .lever:after {
  content: '';
  position: absolute;
  display: inline-block;
  background-color: #f1f1f1;
  border-radius: 1.3125rem;
  left: -0.3125rem;
  top: -0.475rem;
  box-shadow: 0 0.0625rem 0.1875rem 0.0625rem rgba(0, 0, 0, 0.4);
  width: 1.3125rem;
  height: 1.3125rem;
}
.border-style {
  border: 1px solid #d1d3e2 !important;
}
.cursr {
  cursor: pointer;
}
.dashboard-icon {
  background-color: #be0a27;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

/* Changes 28.12.23 */

label {
  color: #222a60;
}
#root input,
#root textarea,
#root select {
  border-radius: 16px;
  border-color: #222a60;
}
.admin-dashboard-left,
.admin-dashboard-right {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 24px;
}
.text-right {
  flex: 1 1 100%;
}
.image-upload > input {
  display: none;
}
.image-upload img {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

#root .form-group {
  margin-bottom: 0;
}

.form-group.form-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.form-checkbox input {
  order: -1;
  width: 16px;
  height: 16px;
  border: #222a60;
}

.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 50%;
  max-width: 50%;
}

/* Hint wrapper / symbol / text */

.hint-symbol {
  background-color: #818181;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;
}

.hint-text {
  background-color: #818181;
  left: 50%;
  bottom: 20px;
  width: 400px;
  transform: translateX(-50%);
  color: #ffffff;
  padding: 12px;
  border-radius: 16px;
  display: none;
}

.hint-label {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.hint-symbol:hover + .hint-text {
  display: block;
}

.hint-label,
.hint-symbol {
  margin-bottom: 0 !important;
}

.form-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
}

.select-holder img {
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
