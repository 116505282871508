.main-page {
  background-color: #222a60;
  overflow: hidden;
}
.big-card-container{
  flex: 0 0 calc(25% - 2rem);
  max-width: calc(25% - 2rem);
}
.big-card-container .card{
  min-width: 100%;
  border-radius: 32px;
}
.cards-container{
  flex: 0 0 75%;
  max-width: 75%;
}
.cards-container .card{
  flex: 0 0 calc(25% - 2rem);
  max-width: calc(25% - 2rem);
  border-radius: 32px;
}
.main-page .navbar > .container{
  max-width: unset;
  padding-left: 90px;
}