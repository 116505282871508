.edit-article-picture-modal {
}
.edit-article-picture-dialog > .modal-dialog {
  max-width: 100% !important;
  padding: 1rem;
}
.picture_upload_btn {
  cursor: pointer;
  float: right;
}
.picture_upload_btn > input {
  visibility: hidden;
  width: 0;
  height: 0;
}
