.picture-table {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  grid-auto-rows: minmax(100px, auto);
}
.picture {
  max-height: 15rem;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible !important;
}
.picture-image {
  height: 15rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  box-sizing: border-box;
  border: 2px solid transparent;
  overflow: hidden;
  background-color: #80808042;
  position: relative;
}

.picture-controls {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  color: darkred;
  z-index: 1;
}
.picture-active {
}
.picture-circle-inactive {
  color: lightgray;
}
.picture-controls > i {
  font-size: 1.7rem;
}
.picture-controls-sub {
  position: absolute;
  bottom: 0;
  left: 0;
  /* transform: translate(0%, -70%); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: gray;
  padding: 0.3rem;
  color: white;
}
.picture-controls-sub > i {
  margin: 0.3rem;
}
.picture-preview-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.picture-preview-wrapper > i {
  font-size: 2.5rem;
  color: darkred;
  cursor: pointer;
}
.picture-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.preview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  grid-auto-rows: minmax(3rem, auto);
}
.picture-load-preview {
  border: 1px solid #80808042;
  background-color: #80808042;
  margin-bottom: 1rem;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items
.my-masonry-grid_column > div {
  // change div to reference your elements you put in <Masonry>
  background: grey;
  margin-bottom: 30px;
} */

.picture-load-item-wrapper {
  position: relative;
}
.picture-load-controls {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  line-height: 0;
  font-size: 1.2rem;
  transform: translate(50%, -50%);
}
.picture-load-controls:hover {
  color: darkred;
  cursor: pointer;
}
