.pickup-points-heading {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.edit-chain-icon {
  font-size: 1rem;
  height: 100%;
}
.delegate-btn {
  height: min-content;
  align-self: center;
}
