#root .visit-url-buttons a,
#root .visit-url-buttons button {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  background-color: #ffd204;
  border-radius: 16px;
  margin: 0 12px;
  padding: 10px;
}

.visit-url-buttons {
  right: 0 !important;
  left: unset !important;
}

.visit-url-buttons > * {
  white-space: nowrap;
}
