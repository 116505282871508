tr {
  display: flex;
  width: 100%;
}

th {
  display: block;
  background-color: #222a60;
  border: 0;
  color: #ffffff;
  text-transform: uppercase;
}
td,
th {
  margin: 10px !important;
  text-align: center !important;
  flex: 1 1 25%;
  max-width: 25%;
}
td {
  background-color: #e3e8ff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 18px; */
}
.levering-afhal > div:last-child {
  margin-top: 5px;
}
