body {
  margin: 0;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', 'Helvetica Neue', Arial, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.overFlow-class {
  overflow: hidden;
}

html body *:not(.fa) {
  font-family: 'Montserrat', 'Helvetica Neue', Arial, sans-serif !important;
}

.btn-yellow {
  background-color: #ffd204 !important;
  border-radius: 16px;
  overflow: hidden;
}

.btn-yellow:hover {
  filter: brightness(1.1);
  transition: all 0.5s;
}

.btn-qr-code {
  color: #212529 !important;
}

#root form {
  margin-bottom: 6rem !important;
}

#root table tr {
  background-color: unset !important;
}

div:not(.sidebar-wrapper) > li > .active {
  opacity: 1 !important;
}

div:not(.sidebar-wrapper) > li > .active > span {
  text-decoration: underline;
}

div:not(.sidebar-wrapper) > li > a {
  color: #222a60 !important;
}

.sidebar-wrapper .nav .nav-link {
  border-radius: 32px !important;
}

#root .sidebar-wrapper .bg-primary {
  background-color: #252d79 !important;
  opacity: 1;
}

#root .sidebar-wrapper .border-primary {
  border-color: #252d79 !important;
}

.sidebar-wrapper .nav-link {
  border-color: #252d79 !important;
}

/* .nav-link.active {
  opacity: 1 !important;
} */
