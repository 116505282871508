.order-confirmation-img-upload .image-upload {
  display: flex;
  flex-direction: row;
  align-items: unset;
  justify-content: unset;
  flex: unset;
  max-width: unset;
}

.order-confirmation-img-upload .image-upload img {
  width: 200px;
  height: 200px;
}

.remove-img-btn {
  bottom: -30px;
  left: 65px;
  z-index: 10;
}

.order-confirmation-img-upload h6 {
  color: #222a60;
  font-weight: bold;
}

.order-confirmation-img-upload h6,
.order-confirmation-holder label {
  font-size: 16px !important;
}
